import React from "react";
import { Button, createStyles } from "@material-ui/core";

import * as Styled from "./style";
type propTypes = {
  variant?: ButtonVariants;
  text: string;
  onClick: (props: any) => void;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  className?: string;
  size?: ButtonSizes;
};

export enum ButtonVariants {
  Primary = "primary",
  Secondary = "secondary",
}
export enum ButtonSizes {
  Small = "small",
  Medium = "medium",
  Large = "large",
}
const MyButton = ({
  variant = ButtonVariants.Primary,
  text,
  fullWidth,
  style,
  className,
  onClick,
  size,
}: propTypes) => {
  return (
    <Styled.Container style={style} className={className}>
      <Button
        size={size}
        fullWidth={fullWidth}
        classes={{ root: "hamid" }}
        onClick={onClick}
        color={variant}
        variant="contained"
      >
        {text}
      </Button>
    </Styled.Container>
  );
};
export default MyButton;
