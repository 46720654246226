import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const LogoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -80px;
  .button-container {
    margin-top: 26px;
    width: 150px;
  }
`;

export const Moto = styled.img`
  max-width: 500px;
  @media (max-width: 480px) {
    max-width: 300px;
  }
`;

export const CatchPhrase = styled.span`
  font-family: "Roboto", sans-serif;
  margin-top: -12px;
  color: #ffffff;
  font-weight: 600;
  font-size: 29px;
  text-shadow: 3px 6px 12px rgba(0, 0, 0, 0.89);
  @media (max-width: 480px) {
    font-size: 25px;
  }
`;
