import React from "react";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

import * as Styled from "./style";
type propTypes = {
  children: JSX.Element;
  onClose?: () => void;
};

const Modal = (props: propTypes) => {
  return (
    <Styled.Container>
      <Styled.InnerContainer>
        <Styled.modalContainer>
          <Styled.Header>
            {props.onClose && (
              <CancelOutlinedIcon
                classes={{ root: "close-icon" }}
                onClick={props.onClose}
              />
            )}
          </Styled.Header>
          <Styled.Body>{props.children}</Styled.Body>
        </Styled.modalContainer>
      </Styled.InnerContainer>
    </Styled.Container>
  );
};
export default Modal;
