import { createMuiTheme } from "@material-ui/core";
import { green, purple } from "@material-ui/core/colors";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#EF476F",
    },
    secondary: {
      main: "#118AB2",
    },
  },
});
