import React, { useState } from "react";
import * as Styled from "./style";
import Header from "./Header";
import motoImg from "../../assets/logo/moto.png";
import MyButton, { ButtonSizes } from "../../commons/MyButton";
import Modal from "../../commons/Modal";
import SignInModal from "../../components/SignInModal";

const SignUp = () => {
  const [loginModal, showLoginModal] = useState(false);
  return (
    <Styled.Container>
      {loginModal && <SignInModal onClose={() => showLoginModal(false)} />}
      <Header showLoginModal={() => showLoginModal(true)} />
      <Styled.LogoContainer>
        <Styled.LogoBox>
          <Styled.Moto src={motoImg} alt="i believe, but..." />
          <Styled.CatchPhrase>For The Right Ones</Styled.CatchPhrase>
          <MyButton
            onClick={() => showLoginModal(true)}
            size={ButtonSizes.Large}
            fullWidth
            className="button-container"
            text={"SIGN UP"}
          />
        </Styled.LogoBox>
      </Styled.LogoContainer>
    </Styled.Container>
  );
};
export default SignUp;
