import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: space-between;
  .svgclass {
    width: 60px;
  }
`;
