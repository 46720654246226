import React from "react";

import * as Styled from "./style";
import Modal from "../../commons/Modal";
import MyButton, { ButtonVariants } from "../../commons/MyButton";
import AnimatedLogo from "../../assets/logo/AnimatedLogo";

type propTypes = {
  onClose?: () => void;
};

const SignInModal = (props: propTypes) => {
  return (
    <Modal onClose={props.onClose}>
      <Styled.Container>
        <AnimatedLogo />
        <Styled.GetStarted>Get Started</Styled.GetStarted>
        <MyButton
          className={"singin-phonenumber-button"}
          fullWidth
          text={"SIGN IN WITH PHONE NUMBER"}
          onClick={() => {}}
        />

        <MyButton
          fullWidth
          text={"SIGN IN FACEBOOK"}
          onClick={() => {}}
          variant={ButtonVariants.Secondary}
        />
      </Styled.Container>
    </Modal>
  );
};
export default SignInModal;
