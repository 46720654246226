import React from "react";
import * as Styled from "./style";
import MyButton from "../../../commons/MyButton";
import Logo from "../../../assets/logo/Logo";

type propTypes = {
  showLoginModal: () => void;
};

const Header = (props: propTypes) => {
  return (
    <Styled.Container>
      <Styled.InnerContainer>
        <Logo />
        <MyButton text={"LOG IN"} onClick={props.showLoginModal} />
      </Styled.InnerContainer>
    </Styled.Container>
  );
};
export default Header;
