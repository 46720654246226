import styled from "styled-components";

export const Container = styled.div`
  z-index: 100;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.4);
`;

export const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const modalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
  .close-icon {
    cursor: pointer;
    margin-right: 8px;
  }
`;
export const Body = styled.div`
  padding: 0 40px 40px 40px;
`;
