import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .svgclass {
    width: 90px;
  }
  .singin-phonenumber-button {
    margin-bottom: 16px;
  }
`;
export const GetStarted = styled.span`
  font-size: 28px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 40px;
`;
export const Description = styled.span``;
