import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DispatchType, StateTypes } from "../store";

type stateProps = {
  loading: boolean;
  status?: string;
};

const initialState: stateProps = {
  loading: true,
};

const slice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
  },
});

// ********* Actions ***********
export const { setLoading, setStatus } = slice.actions;

// ********* Selectors ***********
export const globalSelector = (state: StateTypes) => state.global;
export const loadingSelector = (state: StateTypes) => state.global.loading;

// ********* Thunk ***********
export const setStatusAsync = (value: string) => (dispatch: DispatchType) => {
  setTimeout(() => {
    dispatch(setStatus(value));
  }, 1000);
};

export default slice.reducer;
