import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import reducers from "./reducers";

export const store = configureStore({
  reducer: reducers,
});

export type StateTypes = ReturnType<typeof store.getState>;
export type DispatchType = typeof store.dispatch;
export const useMyDispatch = () => useDispatch<DispatchType>();
