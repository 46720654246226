import React from "react";
import "./style.css";

export default () => (
  <svg
    className="svgclass"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 552 362"
  >
    <g id="RightWing">
      <path
        d="M275.44,149.25c7.2-10.52,14.83-20.75,22.83-30.71c8.05-9.92,16.59-19.45,25.51-28.63c8.92-9.18,18.35-17.9,28.24-26.09
                c9.9-8.18,20.28-15.83,31.21-22.7c10.94-6.85,22.45-12.88,34.52-17.77l2.26-0.93l2.3-0.84l4.61-1.66c1.55-0.52,3.12-0.98,4.68-1.47
                c1.56-0.49,3.12-0.98,4.72-1.36l4.78-1.22c1.61-0.36,3.23-0.66,4.85-0.99l2.43-0.48l2.46-0.35c1.64-0.21,3.27-0.51,4.93-0.65
                l4.98-0.4c1.66-0.11,3.35-0.08,5.02-0.12c0.84,0,1.68-0.06,2.52,0l2.53,0.14c1.69,0.09,3.37,0.17,5.05,0.45
                c6.73,0.81,13.49,2.48,19.81,5.43c1.59,0.71,3.1,1.6,4.65,2.41c0.79,0.39,1.51,0.88,2.23,1.36l2.19,1.44
                c0.75,0.46,1.4,1.03,2.09,1.55l2.04,1.61c1.35,1.09,2.56,2.3,3.84,3.45c4.94,4.78,9.13,10.17,12.73,15.85
                c3.6,5.69,6.62,11.68,9.18,17.84c2.54,6.17,4.53,12.52,6.15,18.95c1.65,6.43,2.73,12.99,3.43,19.59c0.41,3.29,0.55,6.61,0.75,9.92
                c0.07,3.32,0.17,6.64,0.03,9.96c-0.19,6.64-0.77,13.29-1.95,19.87c-1.16,6.57-2.68,13.12-4.91,19.47
                c-4.29,12.73-10.87,24.86-19.66,35.29c-8.75,10.49-19.73,18.92-31.57,25.31c-11.87,6.4-24.65,10.81-37.7,13.53
                c-13.06,2.71-26.43,3.72-39.78,3c-6.68-0.37-13.36-1.18-19.99-2.59c-6.63-1.41-13.22-3.37-19.63-6.3c-3.2-1.48-6.36-3.18-9.38-5.3
                c-3.01-2.13-5.94-4.59-8.47-7.7c-2.57-3.05-4.59-6.93-5.74-10.74c-1.17-3.85-1.64-7.77-1.55-11.64c0.1-3.87,0.75-7.7,1.98-11.41
                c1.24-3.7,3.06-7.3,5.66-10.54l0.99-1.2c0.36-0.38,0.74-0.76,1.11-1.13c0.38-0.37,0.74-0.76,1.14-1.1l1.23-0.98
                c1.63-1.38,3.38-2.34,5.12-3.35c0.88-0.44,1.77-0.81,2.65-1.21c0.44-0.19,0.89-0.4,1.33-0.57l1.34-0.45
                c0.89-0.29,1.79-0.61,2.68-0.87l2.67-0.64c7.13-1.6,14.12-1.73,20.95-1.3c6.83,0.46,13.51,1.59,20.04,3.2
                c13.05,3.24,25.53,8.43,37,15.49c5.72,3.54,11.22,7.52,16.27,12.08c5.04,4.56,9.68,9.65,13.66,15.28
                c3.98,5.63,7.29,11.8,9.66,18.37c2.41,6.55,3.73,13.53,4.06,20.45c0.67,13.92-2.57,27.55-8,39.84c-5.5,12.3-13.07,23.48-22.14,33.2
                c-9.07,9.71-19.57,18.15-31.48,24.38c-5.96,3.1-12.29,5.62-18.95,7.21c-3.32,0.83-6.75,1.32-10.21,1.58
                c-1.73,0.13-3.47,0.07-5.2,0.1c-1.73-0.02-3.44-0.24-5.16-0.36c-6.85-0.78-13.44-2.71-19.61-5.37c-6.18-2.66-11.95-6.04-17.36-9.83
                c-5.39-3.84-10.36-8.15-15.07-12.69l-3.44-3.49c-1.15-1.16-2.2-2.41-3.31-3.6c-2.23-2.38-4.23-4.94-6.31-7.45
                c-16.02-20.49-27.72-43.8-36.14-68.01c-4.21-12.12-7.64-24.47-10.36-36.96c-2.67-12.5-4.71-25.11-6.03-37.79
                c2.13,12.57,4.74,25.03,8.08,37.29c3.29,12.27,7.24,24.34,11.94,36.1c4.73,11.74,10.07,23.22,16.34,34.15
                c6.29,10.91,13.4,21.32,21.48,30.85c4.1,4.71,8.33,9.29,12.89,13.49c4.59,4.16,9.38,8.08,14.48,11.49
                c5.12,3.37,10.49,6.28,16.08,8.5c5.59,2.21,11.4,3.71,17.24,4.19c11.63,1.07,23.31-2.55,33.86-8.31
                c10.56-5.81,20.05-13.75,28.15-22.82c8.11-9.07,14.78-19.45,19.44-30.51c4.66-11.04,7.26-22.9,6.54-34.5
                c-0.62-11.59-4.97-22.64-11.86-32.07c-3.43-4.72-7.47-9.06-11.92-12.99c-4.46-3.92-9.37-7.39-14.52-10.48
                c-10.32-6.13-21.64-10.83-33.34-13.63c-5.84-1.4-11.78-2.36-17.66-2.71c-5.86-0.34-11.75-0.13-17.06,1.12l-1.97,0.48
                c-0.63,0.19-1.24,0.42-1.86,0.62l-0.93,0.3c-0.3,0.12-0.57,0.26-0.85,0.38c-0.56,0.26-1.15,0.5-1.69,0.76
                c-0.5,0.31-1,0.61-1.51,0.9c-0.55,0.26-0.89,0.67-1.34,0.98l-0.65,0.49c-0.2,0.18-0.37,0.38-0.57,0.57l-0.57,0.57l-0.52,0.65
                c-2.8,3.48-4.44,8.59-4.54,13.57c-0.06,2.49,0.26,4.97,0.96,7.22c0.72,2.27,1.68,4.16,3.21,5.99c3.03,3.69,7.75,6.78,12.9,9.14
                c5.18,2.38,10.86,4.12,16.68,5.36c5.83,1.25,11.83,2,17.87,2.35c12.09,0.69,24.33-0.23,36.23-2.63
                c11.89-2.4,23.47-6.33,34.07-11.96c10.59-5.62,20.19-12.97,27.92-21.94c7.75-8.96,13.7-19.53,17.69-30.87
                c2.07-5.65,3.52-11.54,4.65-17.49c1.15-5.95,1.75-12.02,2.01-18.1c0.17-3.04,0.11-6.09,0.09-9.14c-0.15-3.05-0.24-6.1-0.57-9.13
                c-0.23-3.05-0.7-6.06-1.13-9.09l-0.81-4.51c-0.25-1.51-0.64-2.98-0.95-4.48c-1.36-5.94-3.07-11.81-5.26-17.48
                c-2.21-5.66-4.83-11.13-7.93-16.28c-6.18-10.26-14.44-19.31-24.82-24.29c-5.18-2.57-10.92-4.13-16.8-4.97
                c-5.89-0.8-11.95-1.03-18.02-0.55c-1.52,0.07-3.03,0.32-4.55,0.46l-2.28,0.25l-2.27,0.38c-1.51,0.26-3.03,0.5-4.54,0.79l-4.51,1.01
                c-1.51,0.31-3,0.73-4.49,1.15c-1.49,0.42-2.99,0.81-4.47,1.26l-4.43,1.45L422.82,30l-2.19,0.82c-11.69,4.32-22.98,9.86-33.86,16.16
                c-21.81,12.58-41.79,28.37-60.29,45.69C307.94,109.99,291.09,129.13,275.44,149.25z"
      />
    </g>
    <g id="LeftWing">
      <path
        d="M275.44,165.92c-1.3,12.67-3.32,25.28-5.97,37.78c-2.7,12.48-6.11,24.83-10.3,36.95c-8.37,24.2-20.03,47.51-36.01,68.01
                c-2.07,2.5-4.06,5.07-6.29,7.45c-1.1,1.2-2.14,2.45-3.3,3.6l-3.43,3.49c-4.7,4.54-9.67,8.86-15.04,12.7
                c-5.41,3.8-11.18,7.18-17.35,9.83c-6.17,2.65-12.76,4.58-19.6,5.35l-2.58,0.22c-0.86,0.06-1.72,0.17-2.58,0.14l-2.6-0.01
                c-0.87-0.01-1.74,0.01-2.6-0.09c-1.72-0.18-3.47-0.25-5.15-0.58l-2.54-0.43c-0.85-0.14-1.67-0.38-2.5-0.57
                c-6.65-1.6-12.97-4.13-18.92-7.23c-11.89-6.24-22.37-14.69-31.42-24.41c-9.04-9.73-16.61-20.9-22.08-33.2
                c-5.43-12.28-8.64-25.91-7.95-39.81c0.35-6.92,1.67-13.89,4.08-20.43c2.38-6.56,5.69-12.72,9.67-18.34
                c3.98-5.62,8.62-10.71,13.66-15.26c5.05-4.56,10.54-8.53,16.26-12.06c11.46-7.04,23.93-12.25,36.97-15.48
                c6.52-1.61,13.2-2.74,20.03-3.2c6.82-0.43,13.8-0.3,20.93,1.3l2.67,0.64c0.89,0.26,1.79,0.57,2.68,0.86l1.34,0.45
                c0.44,0.17,0.89,0.38,1.33,0.57c0.89,0.4,1.77,0.78,2.65,1.21c1.74,1.01,3.49,1.96,5.12,3.34l1.23,0.98
                c0.4,0.34,0.76,0.73,1.14,1.1l1.11,1.13l0.99,1.2c2.6,3.23,4.43,6.83,5.67,10.52c1.24,3.7,1.89,7.53,1.99,11.4
                c0.1,3.86-0.36,7.78-1.53,11.63c-1.14,3.81-3.15,7.69-5.71,10.74c-2.52,3.12-5.44,5.59-8.45,7.71c-3.02,2.13-6.17,3.84-9.37,5.32
                c-6.4,2.94-12.99,4.9-19.61,6.32c-6.63,1.41-13.3,2.23-19.98,2.6c-13.35,0.73-26.71-0.27-39.76-2.96
                c-13.04-2.71-25.82-7.11-37.69-13.49c-11.84-6.37-22.83-14.78-31.6-25.24l-3.21-3.97c-1.04-1.35-1.98-2.76-2.97-4.14
                c-0.48-0.7-1-1.38-1.45-2.1l-1.33-2.17c-0.86-1.46-1.83-2.86-2.59-4.38l-2.38-4.48c-0.73-1.52-1.41-3.07-2.12-4.6
                c-0.74-1.52-1.31-3.1-1.93-4.67c-0.6-1.57-1.25-3.13-1.74-4.73c-2.24-6.34-3.76-12.89-4.94-19.45c-1.2-6.57-1.76-13.22-1.98-19.86
                c-0.24-6.64,0.06-13.28,0.75-19.88c0.29-3.3,0.84-6.58,1.33-9.85c0.63-3.26,1.21-6.52,2.06-9.73c3.16-12.87,8.09-25.4,15.24-36.8
                c3.58-5.69,7.75-11.09,12.67-15.88c1.28-1.16,2.52-2.35,3.83-3.46c1.37-1.06,2.7-2.17,4.12-3.17c1.47-0.94,2.88-1.97,4.41-2.82
                c1.54-0.81,3.07-1.67,4.64-2.43c6.32-2.97,13.07-4.65,19.8-5.49c0.84-0.1,1.68-0.25,2.53-0.32l2.53-0.15
                c1.68-0.09,3.37-0.23,5.05-0.16c1.68,0.04,3.36,0.03,5.02,0.11l4.98,0.4c0.83,0.05,1.65,0.16,2.47,0.29l2.46,0.35l2.46,0.35
                c0.82,0.12,1.62,0.31,2.43,0.47l4.85,0.98l4.77,1.22c0.79,0.21,1.59,0.4,2.38,0.63l2.35,0.73l4.68,1.47l4.6,1.66l2.3,0.83
                l2.26,0.93c12.07,4.89,23.57,10.92,34.5,17.76c10.92,6.87,21.3,14.51,31.18,22.7c9.88,8.2,19.3,16.91,28.21,26.1
                c8.91,9.18,17.43,18.72,25.46,28.64c7.98,9.96,15.6,20.2,22.77,30.72c-15.61-20.12-32.44-39.27-50.95-56.59
                c-18.47-17.32-38.44-33.11-60.22-45.68c-10.87-6.3-22.15-11.83-33.83-16.15L128.25,30l-2.21-0.73l-4.42-1.45l-4.47-1.25l-2.23-0.62
                c-0.75-0.2-1.5-0.35-2.25-0.52l-4.51-1.02l-4.54-0.78c-0.76-0.12-1.51-0.28-2.27-0.37l-2.28-0.25l-2.27-0.26
                c-0.76-0.09-1.52-0.17-2.27-0.19c-6.07-0.49-12.12-0.23-18,0.58c-5.87,0.86-11.59,2.43-16.76,5.01
                c-10.34,5.03-18.56,14.11-24.7,24.38C28.89,62.84,24.59,74.4,21.95,86.3c-0.7,2.97-1.21,5.98-1.75,8.98
                c-0.41,3.02-0.88,6.04-1.1,9.08c-0.56,6.07-0.75,12.18-0.45,18.26c0.28,6.08,0.87,12.14,2.04,18.08
                c1.15,5.94,2.59,11.83,4.67,17.46c0.45,1.44,1.05,2.81,1.61,4.2c0.57,1.39,1.09,2.8,1.77,4.13l1.93,4.05l2.15,3.92
                c0.67,1.33,1.55,2.54,2.32,3.81l1.19,1.89c0.4,0.63,0.86,1.21,1.29,1.81c0.88,1.19,1.72,2.42,2.63,3.58l2.84,3.42
                c7.74,8.95,17.33,16.29,27.93,21.89c10.6,5.61,22.17,9.53,34.06,11.92c11.89,2.4,24.13,3.3,36.21,2.61
                c6.03-0.35,12.03-1.1,17.86-2.36c5.82-1.25,11.49-2.99,16.66-5.37c5.15-2.35,9.86-5.45,12.87-9.14c0.77-0.92,1.39-1.85,1.9-2.83
                c0.52-0.98,0.97-2.03,1.3-3.16c0.69-2.24,1-4.72,0.94-7.22c-0.1-4.98-1.75-10.09-4.55-13.56l-0.52-0.65l-0.57-0.56
                c-0.19-0.19-0.36-0.39-0.57-0.57l-0.65-0.49c-0.78-0.72-1.87-1.26-2.85-1.88l-1.69-0.76c-0.29-0.12-0.56-0.27-0.86-0.38l-0.93-0.31
                c-0.62-0.2-1.23-0.43-1.86-0.62l-1.97-0.48c-5.3-1.25-11.19-1.45-17.05-1.11c-5.88,0.35-11.81,1.31-17.65,2.71
                c-11.69,2.8-23.02,7.48-33.33,13.61c-5.14,3.09-10.06,6.55-14.51,10.47c-4.45,3.92-8.49,8.26-11.93,12.98
                c-3.44,4.72-6.26,9.82-8.29,15.22c-2.07,5.39-3.21,11.05-3.59,16.84c-0.74,11.59,1.85,23.44,6.48,34.49
                c4.64,11.07,11.3,21.44,19.4,30.52c8.09,9.08,17.56,17.02,28.11,22.84c5.27,2.9,10.82,5.24,16.51,6.74
                c0.71,0.18,1.42,0.41,2.14,0.54l2.15,0.42c1.43,0.34,2.88,0.42,4.32,0.62c0.72,0.12,1.44,0.11,2.17,0.14l2.17,0.08
                c0.72,0.06,1.45-0.02,2.18-0.05l2.19-0.12c5.83-0.48,11.65-1.97,17.23-4.18c5.59-2.21,10.96-5.13,16.07-8.5
                c5.09-3.42,9.88-7.33,14.45-11.5c4.55-4.21,8.78-8.78,12.87-13.5c8.06-9.53,15.15-19.96,21.41-30.87
                c6.24-10.94,11.57-22.42,16.27-34.16c4.68-11.75,8.6-23.83,11.87-36.1C270.75,190.94,273.34,178.48,275.44,165.92z"
      />
    </g>
    <g id="Body">
      <path
        d="M275.44,83.85c1.11,5,1.98,9.99,2.77,14.99c0.81,5,1.48,9.99,2.12,14.99c1.25,9.99,2.18,19.98,2.95,29.97
                c1.51,19.98,2.14,39.96,2.16,59.95c0,19.98-0.63,39.96-2.14,59.95c-0.76,9.99-1.7,19.98-2.95,29.97c-0.64,5-1.32,9.99-2.13,14.99
                c-0.8,5-1.67,9.99-2.79,14.99c-1.12-5-1.99-9.99-2.79-14.99c-0.81-5-1.48-9.99-2.13-14.99c-1.25-9.99-2.19-19.98-2.95-29.97
                c-1.51-19.98-2.13-39.96-2.14-59.95c0.02-19.98,0.65-39.96,2.16-59.95c0.76-9.99,1.7-19.98,2.95-29.97c0.64-5,1.31-9.99,2.12-14.99
                C273.47,93.84,274.33,88.85,275.44,83.85z"
      />
    </g>
    <circle id="Head" className="st0" cx="275.44" cy="88.98" r="19.23" />
  </svg>
);
